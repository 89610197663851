<template>
    <div class="footer">Sacha Goldman</div>
</template>

<style lang="scss">
@use "@/assets/global";

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    padding: 30px;

    color: global.$subtle-color;
    @media (prefers-color-scheme: dark) {
        color: global.$dark-theme-subtle-color;
    }
}
</style>
