import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "nav-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavButtons = _resolveComponent("NavButtons")
  const _component_DropDownButton = _resolveComponent("DropDownButton")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_NavButtons, {
      class: "nav-bar-item-container-desktop",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDropDownVisible = false))
    }),
    _createVNode(_Transition, { name: "drop-down" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_NavButtons, {
          class: "nav-bar-item-container-mobile",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDropDownVisible = false))
        }, null, 512), [
          [_vShow, _ctx.isDropDownVisible]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_DropDownButton, {
      id: "drop-down-button",
      "is-open": _ctx.isDropDownVisible,
      onClick: _ctx.onDropDownButtonClick
    }, null, 8, ["is-open", "onClick"])
  ]))
}