
import {
    defineComponent,
    ref,
    onMounted,
    onBeforeUnmount,
    watchEffect,
} from "vue";
import NavButtons from "@/components/navigation/NavButtons.vue";
import DropDownButton from "@/components/navigation/DropDownButton.vue";

export default defineComponent({
    components: { NavButtons, DropDownButton },
    setup() {
        const isDropDownVisible = ref(false);

        watchEffect(() => {
            if (isDropDownVisible.value) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "";
            }
        });

        onMounted(() => {
            onWindowResize();
            window.addEventListener("resize", onWindowResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener("resize", onWindowResize);
        });

        function onWindowResize() {
            if (window.innerWidth > 760) {
                isDropDownVisible.value = false;
            }
        }

        function onDropDownButtonClick() {
            isDropDownVisible.value = !isDropDownVisible.value;
        }

        return {
            onDropDownButtonClick,
            isDropDownVisible,
        };
    },
});
