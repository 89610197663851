import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (_ctx.useA)
    ? (_openBlock(), _createBlock("a", {
        key: 0,
        class: "nav-bar-button",
        rel: "noopener noreferrer",
        href: _ctx.link
      }, _toDisplayString(_ctx.label), 9, ["href"]))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        class: "nav-bar-button",
        to: _ctx.link
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["to"]))
}