
import { defineComponent } from "vue";
import NavButton from "@/components/navigation/NavButton.vue";

export default defineComponent({
    components: { NavButton },
    emits: ["click"],
    setup(props, context) {
        function onNavButtonClick() {
            context.emit("click");
        }

        return { onNavButtonClick };
    },
});
