import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createVNode(_component_router_view, { class: "content" }, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "router-view-fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}